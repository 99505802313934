import { extendTailwindMerge } from "tailwind-merge";

// -----------------------------------------------------------------------------

const fontSizes = ["2xs", "xs", "sm", "md", "h6", "h5", "h4", "h3", "h2", "h1"];

const spacings = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21, 22, 24,
  25, 26, 27, 28, 29, 30, 32, 34, 36, 38, 39, 40, 41, 42, 44, 48, 50, 52, 53,
  54, 56, 60, 62, 64, 65, 66, 70, 72, 80, 82, 90, 92, 96, 100, 108, 110, 112,
  120, 128, 130, 140, 143, 144, 148, 160, 176, 180, 187, 192, 200, 208, 210,
  211, 215, 220, 224, 240, 254, 256, 270, 277, 280, 288, 290, 295, 300, 315,
  320, 325, 350, 370, 380, 384, 390, 400, 410, 438, 450, 450, 466, 473, 480,
  500, 530, 560, 579, 600, 622, 624, 655, 702, 708, 800, 850, 1200, 1700,
];

export const spacing = Object.fromEntries(
  [[0, "0px"]].concat(spacings.map((s) => [`${s}px`, `${s / 16}rem`])),
);

// -----------------------------------------------------------------------------

/** Over-customization breaks defaults, extending is neccessary */
export const twMerge = extendTailwindMerge({
  extend: {
    theme: {
      spacing: [0].concat(spacings.map((s) => `${s}px`)),
    },
    classGroups: {
      "font-size": [{ text: fontSizes }],
      leading: [{ leading: ["none", "tight", ...fontSizes] }],
    },
    conflictingClassGroups: {
      "font-size": ["leading"],
    },
    conflictingClassGroupModifiers: {
      "font-size": ["leading"],
    },
  },
});
